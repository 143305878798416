@charset "UTF-8";
::placeholder {
  color: #415c81;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  outline: 0;
}

ul[class],
ol[class] {
  padding: 0;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
  font-style: normal;
  font-weight: normal;
}

body {
  min-width: 320px;
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  position: relative;
  overflow-x: hidden;
}

ul[class],
ol[class] {
  list-style: none;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

input,
button,
textarea,
select {
  font: inherit;
}

h1 {
  font-weight: bold;
  font-size: 32px;
  line-height: 48px;
}

h2 {
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
}

h3 {
  font-size: 18px;
  line-height: 28px;
}

h4 {
  font-size: 16px;
  line-height: 24px;
}

h5 {
  font-size: 14px;
  line-height: 22px;
}

h6 {
  font-size: 12px;
  line-height: 20px;
}

p {
  font-size: 16px;
  line-height: 28px;
  margin: 0;
  margin-bottom: 10px;
}

blockquote {
  margin: 0;
  padding-left: 18px;
  border-left: 2px solid #3d587d;
}
blockquote p {
  font-size: 16px;
  line-height: 24px;
}

a {
  color: #1E2A5D;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

.form-radio {
  display: inline-flex;
  align-items: center;
  position: relative;
  margin-right: 20px;
}
.form-radio:last-child {
  margin-right: 0;
}
.form-radio:hover .form-radio__btn + label::before {
  border-color: #1E2A5D;
}
.form-radio:hover .form-radio__btn:disabled + label::before {
  border: 1px solid #3d587d;
  background-color: #3d587d;
}
.form-radio .form-radio__btn {
  display: none;
}
.form-radio .form-radio__btn + label {
  color: #415c81;
  font-size: 16px;
  line-height: 20px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
.form-radio .form-radio__btn + label::before {
  content: "";
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border: 1px solid #3d587d;
  background-color: #3d587d;
  border-radius: 50%;
  box-sizing: border-box;
  transition: 0.3s;
}
.form-radio .form-radio__btn:checked + label::before {
  border: 5px solid #4F88D9;
}
.form-radio .form-radio__btn:disabled + label {
  user-select: none;
  pointer-events: none;
  opacity: 0.5;
}

.form-checkbox {
  display: inline-flex;
  align-items: center;
  position: relative;
  margin-right: 20px;
}
.form-checkbox .form-checkbox__btn {
  display: none;
}
.form-checkbox .form-checkbox__btn + label {
  display: inline-block;
  vertical-align: middle;
  font-size: 13px;
  font-weight: 100;
  color: #91a7ba;
  cursor: pointer;
}
.form-checkbox .form-checkbox__btn + label a {
  color: #fe2837;
}
.form-checkbox .form-checkbox__btn + label::before {
  content: "";
  width: 22px;
  height: 22px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  border: 1px solid #3d587d;
  background-color: #001935;
  border-radius: 5px;
  box-sizing: border-box;
  transition: 0.3s;
}
.form-checkbox .form-checkbox__btn:checked + label::before {
  background-color: #3d587d;
  border-color: #3d587d;
}
.form-checkbox .form-checkbox__btn:checked + label::after {
  content: "";
  width: 7px;
  height: 12px;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  position: absolute;
  left: 8px;
  top: 4px;
  transform: rotate(45deg);
  transition: 0.3s;
}
.form-checkbox .form-checkbox__btn:disabled + label {
  user-select: none;
  pointer-events: none;
  opacity: 0.5;
}
.form-checkbox:last-child {
  margin-right: 0;
}
.form-checkbox:hover .form-checkbox__btn + label::before {
  border-color: #4F88D9;
  background-color: #4F88D9;
}
.form-checkbox:hover .form-checkbox__btn:disabled + label::before {
  border: 1px solid #3d587d;
  background-color: #3d587d;
}

.form-switch {
  width: 60px;
  height: 26px;
  display: inline-flex;
  align-items: center;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  margin-right: 20px;
}
.form-switch:last-child {
  margin-right: 0;
}
.form-switch .form-switch__btn {
  display: none;
}
.form-switch .form-switch__btn + label,
.form-switch .form-switch__btn:disabled + label {
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #3d587d;
}
.form-switch .form-switch__btn:disabled + label {
  user-select: none;
  pointer-events: none;
  opacity: 0.75;
}
.form-switch .form-switch__btn + label::before,
.form-switch .form-switch__btn:disabled + label::before {
  content: "";
  width: 18px;
  height: 18px;
  background-color: transparent;
  border: 1px solid #1E2A5D;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(5px, 4px);
  transition: 0.3s ease;
  box-sizing: border-box;
}
.form-switch .form-switch__btn:checked + label {
  background-color: #4F88D9;
}
.form-switch .form-switch__btn:checked + label::before {
  transform: translate(36px, 4px);
  background-color: #fff;
  border: transparent;
}

.form-group {
  margin-bottom: 15px;
}
.form-group:last-child {
  margin-bottom: 0;
}
.form-group .form-label {
  display: inline-block;
  margin-bottom: 8px;
  font-size: 14px;
  cursor: pointer;
}
.form-group .form-text {
  font-size: 12px;
  color: #163d68;
}
.form-group .form-text a {
  color: #163d68;
}

.form-control {
  display: block;
  width: 100%;
  height: 42px;
  padding: 20px;
  font-size: 16px;
  color: #415c81;
  font-weight: 300;
  background-color: #062445;
  border: 1px solid transparent;
  border-radius: 6px;
  box-sizing: border-box;
  transition: 0.2s ease-in-out;
  position: relative;
}
.form-control:focus {
  color: #415c81;
  border-color: #1e2a5d;
  outline: none;
}
.form-control:disabled {
  user-select: none;
  pointer-events: none;
  opacity: 0.36;
}
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

textarea.form-control {
  resize: vertical;
  min-height: 100px;
}

.btn, button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 8px;
  padding: 0 54px;
  font-size: 18px;
  color: #324e72;
  appearance: none;
  position: relative;
  z-index: 5;
  transition: 0.3s ease-in-out;
  transform: skewX(-10deg);
}
.btn span, button span {
  transform: skewX(10deg);
  z-index: 5;
  position: relative;
}
.btn.no-transform, button.no-transform {
  transform: none;
}
.btn.no-transform span, button.no-transform span {
  transform: none;
}
.btn:disabled, button:disabled {
  opacity: 0.65;
  user-select: none;
  pointer-events: none;
}

.btn--primary {
  color: #fff;
  background-image: -moz-linear-gradient(0deg, #c80469 0%, #ff2837 100%);
  background-image: -webkit-linear-gradient(0deg, #c80469 0%, #ff2837 100%);
  background-image: -ms-linear-gradient(0deg, #c80469 0%, #ff2837 100%);
  background-image: linear-gradient(0deg, #c80469 0%, #ff2837 100%);
  border: none;
}
.btn--primary:hover {
  background-color: #0069d9;
  border-color: #0062cc;
}
.btn--primary:focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn--success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn--success:hover {
  background-color: #218838;
  border-color: #1e7e34;
}
.btn--success:focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn--outline {
  border: 1px solid #324e72;
}

.btn--secondary {
  background-color: #001935;
}

.nice-select {
  -webkit-tap-highlight-color: transparent;
  background-color: #fff;
  border-radius: 5px;
  border: solid 1px #e8e8e8;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  font-family: inherit;
  font-size: 14px;
  font-weight: normal;
  height: 42px;
  line-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 30px;
  position: relative;
  text-align: left;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  width: auto;
  max-width: none;
}
.nice-select:hover {
  border-color: #dbdbdb;
}
.nice-select:active, .nice-select.open, .nice-select:focus {
  border-color: #999;
}
.nice-select:after {
  border-bottom: 2px solid #999;
  border-right: 2px solid #999;
  content: "";
  display: block;
  height: 5px;
  margin-top: -4px;
  pointer-events: none;
  position: absolute;
  right: 12px;
  top: 50%;
  -webkit-transform-origin: 66% 66%;
  -ms-transform-origin: 66% 66%;
  transform-origin: 66% 66%;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  width: 5px;
}
.nice-select.open:after {
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
.nice-select.open .select-list {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: scale(1) translateY(0);
  -ms-transform: scale(1) translateY(0);
  transform: scale(1) translateY(0);
}
.nice-select.disabled {
  border-color: #ededed;
  color: #999;
  pointer-events: none;
}
.nice-select.disabled:after {
  border-color: #cccccc;
}
.nice-select.wide {
  width: 100%;
}
.nice-select.wide .select-list {
  width: 100%;
  left: 0;
}
.nice-select.right {
  margin-left: auto;
  width: auto;
  max-width: none;
}
.nice-select.right .select-list {
  left: auto;
  right: 0;
}
.nice-select.small {
  font-size: 12px;
  height: 36px;
  line-height: 34px;
}
.nice-select.small:after {
  height: 4px;
  width: 4px;
}
.nice-select.small .option {
  line-height: 34px;
  min-height: 34px;
}
.nice-select .select-list {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
  box-sizing: border-box;
  margin-top: 4px;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: calc(100% + 1px);
  left: 0;
  transform-origin: 50% 0;
  transform: scale(0.75) translateY(-21px);
  transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  z-index: 9;
}
.nice-select .select-list:hover .option:not(:hover) {
  background-color: transparent !important;
}
.nice-select .option {
  cursor: pointer;
  font-weight: 400;
  line-height: 40px;
  list-style: none;
  min-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 29px;
  text-align: left;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.nice-select .option:hover, .nice-select .option.focus {
  background-color: #f6f6f6;
}
.nice-select .option.selected {
  font-weight: bold;
}
.nice-select .option.selected.focus {
  background-color: #f6f6f6;
}
.nice-select .option.disabled {
  background-color: transparent;
  color: #999;
  cursor: default;
}

.no-csspointerevents .nice-select .select-list {
  display: none;
}
.no-csspointerevents .nice-select.open .select-list {
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
@font-face {
  font-family: "HelloDenver";
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url(/54ca2f0c2db4c63ada05571268eb2efb.woff2) format("woff2"), url(/35338194406c00cc0aaa1fd202e18381.woff) format("woff");
}
@font-face {
  font-family: "HelloDenver";
  font-style: normal;
  font-weight: 700;
  font-display: auto;
  src: url(/1770cc3222b650fc1aa9e92dd8360d65.woff2) format("woff2"), url(/a94ae069ca6709a97a1d0b609acade19.woff) format("woff");
}
@font-face {
  font-family: "MuseoSans";
  font-style: normal;
  font-weight: 100;
  font-display: auto;
  src: url(/85012a074263edf7bfdba26a47e1bed9.woff2) format("woff2"), url(/d232c270146168bfdc6ff52570ada67b.woff) format("woff");
}
@font-face {
  font-family: "MuseoSans";
  font-style: italic;
  font-weight: 100;
  font-display: auto;
  src: url(/85012a074263edf7bfdba26a47e1bed9.woff2) format("woff2"), url(/d232c270146168bfdc6ff52570ada67b.woff) format("woff");
}
@font-face {
  font-family: "MuseoSans";
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url(/69629eb70baae9e33c0ff9290c708a78.woff2) format("woff2"), url(/ff371ead6a5e7a8c5fd2a9aefd44a7eb.woff) format("woff");
}
@font-face {
  font-family: "MuseoSans";
  font-style: italic;
  font-weight: 300;
  font-display: auto;
  src: url(/69629eb70baae9e33c0ff9290c708a78.woff2) format("woff2"), url(/ff371ead6a5e7a8c5fd2a9aefd44a7eb.woff) format("woff");
}
@font-face {
  font-family: "MuseoSans";
  font-style: normal;
  font-weight: 500;
  font-display: auto;
  src: url(/0999164134dc7766799647a7cf483e65.woff2) format("woff2"), url(/550f88bdfb38423ffa680f1815bdeb0d.woff) format("woff");
}
@font-face {
  font-family: "MuseoSans";
  font-style: italic;
  font-weight: 500;
  font-display: auto;
  src: url(/0999164134dc7766799647a7cf483e65.woff2) format("woff2"), url(/550f88bdfb38423ffa680f1815bdeb0d.woff) format("woff");
}
@font-face {
  font-family: "MuseoSans";
  font-style: normal;
  font-weight: 700;
  font-display: auto;
  src: url(/ab3af2b97617cb6a328437403ab951d3.woff2) format("woff2"), url(/22b121d683773a91e483d4b0f45a7703.woff) format("woff");
}
@font-face {
  font-family: "MuseoSans";
  font-style: italic;
  font-weight: 700;
  font-display: auto;
  src: url(/ab3af2b97617cb6a328437403ab951d3.woff2) format("woff2"), url(/22b121d683773a91e483d4b0f45a7703.woff) format("woff");
}
@font-face {
  font-family: "MuseoSans";
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url(/5848c395e1e9f9cecc2ddccfc173309a.woff2) format("woff2"), url(/2ea52c4f1b078ecce98317337414fe94.woff) format("woff");
}
@font-face {
  font-family: "MuseoSans";
  font-style: italic;
  font-weight: 900;
  font-display: auto;
  src: url(/5848c395e1e9f9cecc2ddccfc173309a.woff2) format("woff2"), url(/2ea52c4f1b078ecce98317337414fe94.woff) format("woff");
}
.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
}

.swiper-container-vertical > .swiper-wrapper {
  flex-direction: column;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}

.swiper-container-android .swiper-slide, .swiper-wrapper {
  transform: translate3d(0px, 0, 0);
}

.swiper-container-multirow > .swiper-wrapper {
  flex-wrap: wrap;
}

.swiper-container-multirow-column > .swiper-wrapper {
  flex-wrap: wrap;
  flex-direction: column;
}

.swiper-container-free-mode > .swiper-wrapper {
  transition-timing-function: ease-out;
  margin: 0 auto;
}

.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
}

.swiper-slide-invisible-blank {
  visibility: hidden;
}

.swiper-container-autoheight {
  height: auto;
}
.swiper-container-autoheight .swiper-slide {
  height: auto;
}
.swiper-container-autoheight .swiper-wrapper {
  align-items: flex-start;
  transition-property: transform, height;
}

.swiper-container-3d {
  perspective: 1200px;
}
.swiper-container-3d .swiper-wrapper, .swiper-container-3d .swiper-slide, .swiper-container-3d .swiper-slide-shadow-left, .swiper-container-3d .swiper-slide-shadow-right, .swiper-container-3d .swiper-slide-shadow-top, .swiper-container-3d .swiper-slide-shadow-bottom, .swiper-container-3d .swiper-cube-shadow {
  transform-style: preserve-3d;
}
.swiper-container-3d .swiper-slide-shadow-left, .swiper-container-3d .swiper-slide-shadow-right, .swiper-container-3d .swiper-slide-shadow-top, .swiper-container-3d .swiper-slide-shadow-bottom {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
}
.swiper-container-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.swiper-container-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.swiper-container-3d .swiper-slide-shadow-top {
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

.swiper-container-css-mode .swiper-wrapper {
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.swiper-container-css-mode .swiper-wrapper::-webkit-scrollbar {
  display: none;
}
.swiper-container-css-mode .swiper-wrapper .swiper-slide {
  scroll-snap-align: start start;
}

.swiper-container-horizontal.swiper-container-css-mode > .swiper-wrapper {
  scroll-snap-type: x mandatory;
}

.swiper-container-vertical.swiper-container-css-mode > .swiper-wrapper {
  scroll-snap-type: y mandatory;
}

.swiper-button-prev, .swiper-button-next {
  position: absolute;
  top: 50%;
  width: calc(44px / 44 * 27);
  height: 44px;
  margin-top: calc(-1 * 44px / 2);
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fdf;
}

.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}

.swiper-button-prev:after, .swiper-button-next:after {
  font-size: 44px;
  text-transform: none !important;
  letter-spacing: 0;
  text-transform: none;
  font-variant: initial;
}

.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
  left: 10px;
  right: auto;
}

.swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after {
  content: "prev";
}

.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
  right: 10px;
  left: auto;
}

.swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after {
  content: "next";
}

.swiper-button-prev.swiper-button-white, .swiper-button-next.swiper-button-white {
  background-color: #ffffff;
}

.swiper-button-prev.swiper-button-black, .swiper-button-next.swiper-button-black {
  background-color: #000000;
}

.swiper-button-lock {
  display: none;
}

.swiper-pagination {
  position: absolute;
  text-align: center;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
}
.swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}

.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 10px;
  left: 0;
  width: 100%;
}

.swiper-pagination-bullets-dynamic {
  overflow: hidden;
  font-size: 0;
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  transform: scale(0.33);
  position: relative;
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active, .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
  transform: scale(1);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
  transform: scale(0.66);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
  transform: scale(0.33);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
  transform: scale(0.66);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
  transform: scale(0.33);
}

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: 0.2;
}

button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #fdf;
}

.swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  transform: translate3d(0px, -50%, 0);
}
.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 6px 0;
  display: block;
}
.swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
}
.swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  display: inline-block;
  transition: 200ms transform, 200ms top;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 4px;
}
.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}
.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  transition: 200ms transform, 200ms left;
}
.swiper-container-horizontal.swiper-container-rtl > .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  transition: 200ms transform, 200ms right;
}

.swiper-pagination-progressbar {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
}
.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: #fdf;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: scale(0);
  transform-origin: left top;
}

.swiper-container-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  transform-origin: right top;
}

.swiper-container-horizontal > .swiper-pagination-progressbar {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0;
}

.swiper-container-vertical > .swiper-pagination-progressbar {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0;
}
.swiper-container-vertical > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0;
}

.swiper-container-horizontal > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0;
}

.swiper-pagination-white {
  background-color: #ffffff;
}

.swiper-pagination-black {
  background-color: #000000;
}

.swiper-pagination-lock {
  display: none;
}

.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none;
  background: rgba(0, 0, 0, 0.1);
}

.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%;
}

.swiper-container-vertical > .swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%;
}

.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0;
}

.swiper-scrollbar-cursor-drag {
  cursor: move;
}

.swiper-scrollbar-lock {
  display: none;
}

.swiper-zoom-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.swiper-zoom-container > img, .swiper-zoom-container > svg, .swiper-zoom-container > canvas {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.swiper-slide-zoomed {
  cursor: move;
}

.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  transform-origin: 50%;
  animation: swiper-preloader-spin 1s infinite linear;
  box-sizing: border-box;
  border: 4px solid #fdf;
  border-radius: 50%;
  border-top-color: transparent;
}

.swiper-lazy-preloader-white {
  background-color: #fff;
}

.swiper-lazy-preloader-black {
  background-color: #000;
}

@keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg);
  }
}
.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
}

.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  transition-timing-function: ease-out;
}
.swiper-container-fade .swiper-slide {
  pointer-events: none;
  transition-property: opacity;
}
.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none;
}
.swiper-container-fade .swiper-slide-active {
  pointer-events: auto;
}
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-container-cube {
  overflow: visible;
}
.swiper-container-cube .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
  visibility: hidden;
  transform-origin: 0 0;
  width: 100%;
  height: 100%;
}
.swiper-container-cube .swiper-slide .swiper-slide {
  pointer-events: none;
}
.swiper-container-cube.swiper-container-rtl .swiper-slide {
  transform-origin: 100% 0;
}
.swiper-container-cube .swiper-slide-active {
  pointer-events: auto;
  pointer-events: auto;
  visibility: visible;
}
.swiper-container-cube .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}
.swiper-container-cube .swiper-slide-next, .swiper-container-cube .swiper-slide-prev, .swiper-container-cube .swiper-slide-next + .swiper-slide {
  pointer-events: auto;
  visibility: visible;
}
.swiper-container-cube .swiper-slide-shadow-top, .swiper-container-cube .swiper-slide-shadow-bottom, .swiper-container-cube .swiper-slide-shadow-left, .swiper-container-cube .swiper-slide-shadow-right {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.swiper-container-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  z-index: 0;
}

.swiper-container-flip {
  overflow: visible;
}
.swiper-container-flip .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
}
.swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none;
}
.swiper-container-flip .swiper-slide-active {
  pointer-events: auto;
}
.swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}
.swiper-container-flip .swiper-slide-shadow-top, .swiper-container-flip .swiper-slide-shadow-bottom, .swiper-container-flip .swiper-slide-shadow-left, .swiper-container-flip .swiper-slide-shadow-right {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.modal__overlay {
  position: fixed;
  z-index: 300;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  backface-visibility: hidden;
  perspective: 1000;
}

.modal__container {
  background-color: #001935;
  max-width: 800px;
  min-width: 630px;
  max-height: 95vh;
  width: auto;
  overflow-y: auto;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 35px;
}

.modal__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.modal__header .section-text {
  margin-bottom: 0;
}
.modal__header .section-text .section-title {
  color: #91a7ba;
}
.modal__header .section-text .section-title span {
  color: #fff;
}
.modal__header .modal__close {
  width: 16px;
  height: 16px;
  padding: 0;
  margin: 0;
  border: 0;
  transform: none;
  background: none;
  outline: none;
  margin-left: auto;
  justify-content: flex-end;
}
.modal__header .modal__close:before {
  content: "✕";
  color: #1e3d5e;
  cursor: pointer;
  font-size: 20px;
  line-height: 16px;
  font-weight: 900;
  transition: 0.3s;
}
.modal__header .modal__close:hover::before {
  color: #fff;
}

.modal__content {
  padding-top: 35px;
}
.modal__content .form-control {
  height: 65px;
  padding-left: 54px;
}
.modal__content .form-group {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 24px;
}
.modal__content .form-group:last-child {
  margin-bottom: 0;
}
.modal__content .form-group .form-icon {
  position: absolute;
  left: 20px;
  top: 50%;
  z-index: 5;
  width: 16px;
  height: 16px;
  fill: #415c81;
  transform: translateY(-50%);
}
.modal__content .form-group .form-input {
  width: 100%;
  position: relative;
}
.modal__content .form-group .form-text {
  display: flex;
  flex: 50%;
}
.modal__content .form-group .form-text.last {
  justify-content: flex-end;
}
.modal__content .form-group .btn {
  width: 120px;
  padding: 0;
  height: 65px;
  transform: none;
}
.modal__content .form-group .btn.btn--secondary {
  margin-left: 18px;
  background-color: #062445;
  color: #fff;
}
.modal__content .form-group .social__block {
  margin-left: auto;
}
.modal__content .form-group .social__block--link {
  margin: 0;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.modal__content .games-tabs {
  margin-bottom: 48px;
}

#modal-thanks {
  text-align: center;
}
#modal-thanks .modal__header {
  padding-bottom: 0;
}
#modal-thanks .modal-thanks__icon {
  fill: #28a745;
  height: 100px;
}
#modal-thanks .modal-thanks__title {
  font-size: 22px;
  font-weight: 700;
  margin-top: 15px;
}
#modal-thanks .modal-thanks__text {
  margin-bottom: 30px;
  font-size: 14px;
  font-weight: 400;
  color: #777;
}
#modal-thanks .btn {
  padding-left: 30px;
  padding-right: 30px;
  text-transform: uppercase;
}

#modal-profile {
  width: 95%;
}
#modal-profile .modal__container {
  width: 100%;
  max-width: 1200px;
}

@media (max-width: 560px) {
  .modal__container {
    max-width: calc(100% - 30px);
    min-width: auto;
    padding: 25px;
  }
  .modal__container .section-text {
    margin-bottom: 0;
  }
  .modal__container .form-group .btn {
    width: 46.9%;
  }
  .modal__container .form-group .btn.btn--secondary {
    margin-left: auto;
  }
  .modal__container .form-group .social__block {
    margin-left: auto;
    margin-right: auto;
    margin-top: 24px;
  }
}
@keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes mmfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes mmslideIn {
  from {
    transform: translateY(15%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes mmslideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10%);
  }
}
.micromodal-slide {
  display: none;
}

.micromodal-slide.is-open {
  display: block;
}

.micromodal-slide[aria-hidden=false] .modal__overlay {
  animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden=false] .modal__container {
  animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden=true] .modal__overlay {
  animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden=true] .modal__container {
  animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
  will-change: transform;
}

.modal__overlay--close {
  height: 100%;
  width: 100%;
  position: absolute;
}

body {
  font-family: "MuseoSans", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #91a7ba;
  padding-bottom: 370px;
  background-color: #000b26;
}

.mobile {
  display: none;
}

.logo {
  width: 81px;
  height: 78px;
  background: url(/fa52605b58a27deda04581c1425b78d3.png) no-repeat;
  background-size: contain;
  margin-right: 35px;
}

.container {
  width: 100%;
  position: relative;
  box-sizing: border-box;
}

.section--wrap {
  height: 100%;
  position: relative;
  z-index: 3;
  display: flex;
  align-items: center;
  color: #fff;
}

.section-text {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
.section-text .section-icon {
  max-width: 22px;
  max-height: 22px;
  margin-right: 20px;
  fill: #fe2837;
}
.section-text .section-title {
  font-size: 22px;
  font-weight: 300;
  color: #91a7ba;
}
.section-text .section-title span {
  font-weight: 500;
  color: #fff;
}

.section-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.section-top .btn {
  height: 50px;
  padding-left: 25px;
  padding-right: 25px;
  color: #fff;
  text-decoration: none;
  font-weight: 500;
}
.section-top .btn span {
  width: 100%;
  display: flex;
  align-items: center;
}
.section-top .btn span svg {
  margin-left: 100px;
  width: 14px;
  height: 14px;
  fill: #ff2837;
}

.btn.second {
  display: none;
}

.header {
  height: 110px;
  width: 100%;
  padding-left: 35px;
  padding-right: 35px;
  z-index: 5;
  position: relative;
  background-color: #000b26;
  border-bottom: 1px solid #062035;
  backface-visibility: hidden;
  perspective: 1000;
}

.header__languages {
  margin-right: 30px;
  position: relative;
}
.header__languages .flag {
  width: 25px;
  height: 25px;
}
.header__languages .header-lang__current {
  height: 50px;
  padding-left: 15px;
  padding-right: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #001935;
  border-radius: 8px;
}
.header__languages .header-lang__current::after, .header__languages .header-lang__current::before {
  content: "";
  width: 7px;
  height: 1px;
  background-color: #324e72;
  border-radius: 5px;
  position: absolute;
  right: 15px;
  transition: 0.3s ease;
}
.header__languages .header-lang__current::before {
  transform: rotate(45deg);
  right: 20px;
}
.header__languages .header-lang__current::after {
  transform: rotate(-45deg);
}
.header__languages .header-lang__current.active {
  border-radius: 8px 8px 0 0;
}
.header__languages .header-lang__current.active::before {
  transform: rotate(-45deg);
}
.header__languages .header-lang__current.active::after {
  transform: rotate(45deg);
}
.header__languages .header-lang__list {
  background-color: #001935;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 8px;
  border-radius: 0 0 8px 8px;
  position: absolute;
  width: 100%;
  height: 0;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
}
.header__languages .header-lang__list--item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.header__languages .header-lang__list.active {
  visibility: visible;
  opacity: 1;
  height: auto;
}

.header__search {
  margin-right: 30px;
  max-width: 357px;
  flex-grow: 1;
  height: 50px;
  background-color: #001935;
  border-radius: 6px;
  padding-left: 10px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  position: relative;
}
.header__search.active .search-list {
  display: block;
}
.header__search:hover .search-list {
  display: block;
}
.header__search .search-list {
  position: absolute;
  top: 52px;
  width: 100%;
  left: 0px;
  border-radius: 6px;
  background-color: #001935;
  max-height: 200px;
  min-height: 141px;
  overflow-y: auto;
  display: none;
  padding: 5px 2px;
}
.header__search .search-list .empty-block {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #324e72;
  text-transform: capitalize;
}
.header__search .search-list > a {
  text-decoration: none;
}
.header__search .search-list > a > div {
  padding: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 2px;
}
.header__search .search-list > a > div:hover {
  background-color: #324e72;
}
.header__search .search-list > a > div img {
  height: 20px;
  margin: auto;
}
.header__search .search-list > a > div div {
  max-width: 84%;
}
.header__search .search-list > a > div div * {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.header__search .search-list > a > div div h5 {
  height: 1.1rem;
  color: #fff;
}
.header__search .search-list > a > div div h6 {
  height: 1rem;
  color: #91a7ba;
}
.header__search form {
  display: flex;
  align-items: center;
  width: 100%;
}
.header__search .header-search__btn {
  padding: 0;
  margin: 0;
  margin-right: 16px;
  width: 32px;
  height: 32px;
  transform: none;
}
.header__search .header-search__btn svg {
  max-width: 16px;
  max-height: 16px;
  fill: #324e72;
  transition: 0.3s;
}
.header__search .header-search__btn:hover svg {
  fill: #fff;
}
.header__search .header-search__field {
  margin: 0;
  padding: 0;
  width: 100%;
  flex-grow: 1;
  background-color: transparent;
  height: 32px;
  border: 0;
  color: #324e72;
}

.header__navigation--link {
  display: inline-flex;
  align-items: center;
  margin-right: 36px;
  color: #324e72;
  line-height: 1.2;
  font-weight: 300;
  transition: 0.25s ease-in-out;
}
.header__navigation--link svg {
  margin-right: 16px;
  max-width: 21px;
  max-height: 21px;
  fill: #324e72;
  transition: 0.25s ease-in-out;
}
.header__navigation--link:hover {
  color: #fff;
}
.header__navigation--link:hover svg {
  fill: #fff;
}
.header__navigation--link:last-child {
  margin-right: 0;
}

.header__info {
  margin-left: auto;
  display: flex;
  align-items: center;
  height: 100%;
}

.header__login {
  height: 100%;
  align-items: center;
  display: flex;
}
.header__login .header__login--btn {
  margin: 0;
  padding: 0;
  padding-left: 54px;
  padding-right: 54px;
  margin-right: 18px;
  height: 54px;
}
.header__login .header__login--btn:last-child {
  margin-right: 0;
}

.header__profile {
  display: flex;
  align-items: center;
  height: 100%;
}
.header__profile .profile__card {
  display: flex;
  align-items: center;
  height: 100%;
}
.header__profile .profile__ava {
  width: 50px;
  height: 50px;
}

.profile__notification {
  margin-right: 38px;
  width: 46px;
  height: 46px;
  border-radius: 6px;
  background-color: #072035;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.profile__notification svg {
  width: 16px;
  height: 20px;
  fill: #3f5c81;
}
.profile__notification--push {
  position: absolute;
  right: -3px;
  top: -3px;
  background-color: #ff304b;
  border-radius: 3px;
  color: #fff;
  font-size: 10px;
  font-weight: 300;
  padding: 2px 5px;
}

.profile__ava {
  width: 50px;
  height: 50px;
  border-radius: 6px;
  overflow: hidden;
  border: 2px solid #ff2837;
  margin-right: 16px;
}
.profile__ava img {
  border: 3px solid #101010;
  border-radius: 6px;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.profile__data {
  margin-top: 30px;
  display: flex;
}
.profile__data .item {
  display: flex;
  flex-direction: column;
  color: white;
  margin-right: 43px;
}
.profile__data .item span {
  color: #ff2837;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 12px;
}
.profile__data .profile-btn {
  width: 120px;
}

.profile__name {
  position: relative;
}
.profile__name::after {
  transform: translateY(-50%) rotate(-45deg);
  right: -26px;
}
.profile__name--username {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
.profile__name--balance {
  color: #ff2837;
  font-weight: 500;
  font-size: 14px;
}

.profile__arrow-down {
  height: 40%;
  width: 10%;
  min-width: 33px;
  position: relative;
  cursor: pointer;
}
.profile__arrow-down::before, .profile__arrow-down::after {
  content: "";
  width: 7px;
  height: 1px;
  position: absolute;
  background-color: #324e72;
  top: 50%;
  margin-left: 33%;
}
.profile__arrow-down::before {
  transform: rotate(45deg);
}
.profile__arrow-down::after {
  transform: translateX(69%) rotate(-45deg);
}
.profile__arrow-down:hover::after, .profile__arrow-down:hover::before {
  background-color: #fff;
}

.profile__logout {
  border-left: 1px solid #062035;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 40px;
  padding-right: 40px;
  margin-right: -35px;
}
.profile__logout svg {
  width: 18px;
  height: 18px;
  fill: #14334f;
  transform: rotate(180deg);
}

.menu__mobile {
  visibility: hidden;
  opacity: 0;
  transform: translateY(-100%);
  transition: 0.4s;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  padding-bottom: 35px;
  background-color: #001935;
}
.menu__mobile.opened {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}
.menu__mobile .header__menu {
  left: 18px;
  top: 48px;
}
.menu__mobile .section--wrap {
  height: 50px;
  padding-left: 42px;
  padding: 58px 18px 35px 60px;
  justify-content: space-between;
}
.menu__mobile .header__languages {
  margin-right: 0;
}
.menu__mobile .menu__title {
  font-size: 18px;
  height: 1.2;
  font-weight: 700;
  color: #fff;
}
.menu__mobile .header__search {
  background-color: #132c46;
  max-width: 100%;
  margin-right: 18px;
  margin-left: 18px;
}
.menu__mobile .sidebar__navigation {
  margin-left: 18px;
  margin-right: 18px;
  margin-top: 24px;
}
.menu__mobile .sidebar__navigation--link {
  width: 100%;
  justify-content: flex-start;
  padding-left: 30px;
  padding-right: 30px;
}

.social__block--link {
  text-decoration: none !important;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: 0.25s ease-in-out;
}
.social__block--link svg {
  max-width: 18px;
  max-height: 18px;
  fill: #324e72;
  transition: 0.25s ease-in-out;
}
.social__block--link:hover svg {
  fill: #fe2837;
}
.social__block--link:last-child {
  margin-right: 0;
}

.sidebar {
  width: 135px;
  height: 100%;
  border-right: 1px solid #062035;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2;
  background-color: #000b26;
  backface-visibility: hidden;
  perspective: 1000;
}
.sidebar .section--wrap {
  flex-direction: column;
}
.sidebar .logo {
  margin-top: 24px;
  margin-bottom: 30px;
}
.sidebar__navigation--link {
  background-image: transparent;
  box-shadow: 0px 5px 96.96px 4.04px transparent;
  border-radius: 8px;
  width: 111px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
  transform: skewX(-8deg);
  transition: 0.25s ease-in-out;
  text-decoration: none !important;
}
.sidebar__navigation--link svg {
  fill: #324e72;
  max-width: 24px;
  max-height: 24px;
  transition: 0.25s ease-in-out;
  transform: skewX(8deg);
}
.sidebar__navigation--link span {
  transform: skewX(8deg);
  margin-left: 20px;
  font-size: 16px;
  font-weight: 500;
  color: #324e72;
}
.sidebar__navigation--link:hover, .sidebar__navigation--link.active {
  background-image: -moz-linear-gradient(0deg, #c80469 0%, #ff2837 100%);
  background-image: -webkit-linear-gradient(0deg, #c80469 0%, #ff2837 100%);
  background-image: -ms-linear-gradient(0deg, #c80469 0%, #ff2837 100%);
  background-image: linear-gradient(0deg, #c80469 0%, #ff2837 100%);
  box-shadow: 0px 5px 96.96px 4.04px rgba(255, 40, 55, 0.6);
}
.sidebar__navigation--link:hover svg, .sidebar__navigation--link.active svg {
  fill: #fff;
}
.sidebar__navigation--link:hover span, .sidebar__navigation--link.active span {
  color: #fff;
}
.sidebar__navigation--link:last-child {
  margin-bottom: 0;
}

.footer {
  height: 370px;
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 5;
  background-color: #000b26;
  backface-visibility: hidden;
  perspective: 1000;
  padding-top: 55px;
  padding-bottom: 35px;
}
.footer .section--wrap {
  border-top: 1px solid #062035;
  padding-top: 55px;
  margin-top: 65px;
  padding-left: 35px;
  padding-right: 35px;
  flex-wrap: wrap;
}
.footer .payments-list {
  margin-left: 35px;
  padding-right: 35px;
}
.footer .col {
  height: 100%;
  margin-right: 30px;
  flex: auto;
}
.footer .col:last-child {
  margin-right: 0;
  flex: 1.5;
}
.footer .col .copyright {
  margin-top: 34px;
  margin-bottom: 34px;
}
.footer .copyright {
  font-weight: 500;
  font-size: 14px;
  line-height: 36px;
  color: #597497;
}

.menu-list__item {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  transition: 0.3s;
}
.menu-list__item--title {
  margin-bottom: 34px;
}
.menu-list__item--link {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #597497;
  transition: 0.3s linear;
}
.menu-list__item svg {
  width: 17px;
  height: 17px;
  fill: #fe2837;
  flex-shrink: 0;
  margin-bottom: auto;
  margin-right: 10px;
}
.menu-list__item--link:hover {
  color: #fff;
}

main {
  height: 100%;
  padding: 35px;
}
main section {
  margin-bottom: 54px;
}
main section:last-child {
  margin-bottom: 0;
}
main section .section-text {
  margin-bottom: 30px;
}
main section .section-top {
  margin-bottom: 50px;
}
main section .similar {
  display: flex;
  align-items: center;
}
main section .similar .section-text {
  margin-bottom: 0;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
}

.start-frame {
  max-width: 100%;
  flex: 1;
  margin-right: 45px;
}

.info-block {
  min-width: 378px;
  max-width: 400px;
}
.info-block .section-text {
  margin-bottom: 32px;
}
.info-block .section-text .section-title {
  color: #91a7ba;
  font-weight: 300;
}
.info-block .section-text .section-title span {
  color: #fff;
  font-weight: 500;
}

.top-winners {
  position: relative;
  padding-left: 34px;
}
.top-winners::before {
  content: "";
  width: 45px;
  height: 339px;
  border-bottom: 1px solid rgba(33, 49, 66, 0.73);
  border-right: 1px solid rgba(33, 49, 66, 0.73);
  border-radius: 0 0 10px 0;
  position: absolute;
  left: -45px;
}
.top-winners .winner-item {
  border-radius: 6px;
  background-color: #001935;
  height: 74px;
  position: relative;
  padding: 18px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.top-winners .winner-item:last-child {
  margin-bottom: 0;
}
.top-winners .winner-item::before {
  content: "";
  width: 28px;
  height: 1px;
  background-color: rgba(33, 49, 66, 0.73);
  position: absolute;
  left: -34px;
  top: 50%;
  transform: translateY(-50%);
}
.top-winners .winner-item::after {
  content: "";
  background: url(/0ed6a35053d8b8686b50a67cd5e7c4a5.png) no-repeat;
  width: 23px;
  height: 23px;
  position: absolute;
  left: -46px;
  top: 50%;
  transform: translateY(-50%);
}
.top-winners .winner-item .winner-ava {
  width: 40px;
  height: 40px;
  margin-right: 20px;
  overflow: hidden;
  border-radius: 6px;
}
.top-winners .winner-item .winner-name__fullname {
  font-weight: 500;
  font-size: 12px;
  color: #91a7ba;
  margin-bottom: 5px;
}
.top-winners .winner-item .winner-name__nickname {
  font-weight: 700;
  font-size: 14px;
  color: #fff;
}
.top-winners .winner-item .winner-info {
  margin-left: auto;
  text-align: right;
  margin-right: 16px;
}
.top-winners .winner-item .winner-time {
  font-size: 10px;
  font-weight: 500;
  margin-bottom: 5px;
}
.top-winners .winner-item .winner-balance {
  font-size: 14px;
  font-weight: 700;
  color: #fff;
}
.top-winners .winner-item .winner-rate {
  width: 30px;
  height: 30px;
  max-width: 30px;
  max-height: 30px;
  border-radius: 50%;
  position: absolute;
  right: -15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 900;
  color: #fff;
}
.top-winners .winner-item .winner-rate.first {
  background-color: #50a0b1;
}
.top-winners .winner-item .winner-rate.second {
  background-color: #d4af37;
}
.top-winners .winner-item .winner-rate.third {
  background-color: #8c8b8f;
}
.top-winners .winner-item .winner-rate.fourth {
  background-color: #78562b;
}

.payments-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.payments-list__item img {
  max-height: 32px;
  opacity: 0.3;
}

.information-text p {
  font-size: 14px;
  color: #91a7ba;
}

.games-wrap {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -32px;
}

.games-tabs__list {
  display: flex;
  align-items: center;
}
.games-tabs__list--item {
  display: inline-flex;
  margin-right: 75px;
  color: #91a7ba;
  position: relative;
  cursor: pointer;
}
.games-tabs__list--item.active {
  color: #fff;
}
.games-tabs__list--item.active::before {
  content: "";
  width: 50%;
  height: 3px;
  display: block;
  background-color: #ff2837;
  border-radius: 2px;
  position: absolute;
  bottom: -12px;
}
.games-tabs__list--item sup {
  color: #ff2837;
  font-weight: 700;
  margin-left: 5px;
  margin-top: -3px;
}
.games-tabs__list--item:last-child {
  margin-right: 0;
}

.games-item {
  max-height: 235px;
  border-radius: 6px;
  display: block;
  margin-right: 32px;
  margin-bottom: 32px;
  position: relative;
  flex-grow: 1;
  max-width: calc(25% - 24px);
}
.games-item::before {
  content: "";
  width: 95%;
  height: 100%;
  border-radius: 6px;
  background-color: #07163d;
  position: absolute;
  left: 50%;
  z-index: -1;
  bottom: -5px;
  transform: translateX(-50%);
  display: none;
  transition: 0.45s;
}
.games-item:nth-child(4n) {
  margin-right: 0;
}
.games-item img {
  width: 100%;
  display: block;
}
.games-item button.btn {
  margin: 0;
  padding: 0;
  transform: none;
  border-radius: 50%;
  position: absolute;
  left: 15px;
  top: 15px;
  width: 38px;
  height: 38px;
  background-color: #00204d;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
}
.games-item button.btn::before, .games-item button.btn::after {
  content: "";
  width: 14px;
  height: 1px;
  background-color: #8da0bc;
  position: absolute;
}
.games-item button.btn::after {
  transform: rotate(90deg);
}
.games-item button.btn:hover {
  background-color: #000;
}
.games-item__info {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  left: 0;
  bottom: 0;
  visibility: hidden;
  opacity: 0;
  width: 100%;
  max-height: 100%;
  padding: 15px 25px;
  transition: 0.45s ease-in-out;
}
.games-item__title {
  display: block;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
}
.games-item__description {
  display: block;
  font-size: 12px;
  line-height: 17px;
  font-weight: 500;
  color: #91a7ba;
  max-height: 72%;
  overflow: hidden;
}
.games-item:hover::before {
  display: block;
}
.games-item:hover .games-item__info {
  visibility: visible;
  opacity: 1;
}
.games-item:hover .btn-favorite {
  opacity: 1;
  visibility: visible;
}

.daily-col {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-right: 32px;
  max-width: calc(25% - 24px);
}
.daily-col:last-child {
  margin-right: 0;
  margin-left: 32px;
}

.jackpot-item {
  width: 100%;
  max-width: 402px;
  max-height: 195px;
  background-color: #001935;
  border-radius: 6px;
  flex-grow: 1;
  margin-bottom: 24px;
  padding: 24px 34px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.jackpot-item__top {
  display: flex;
  align-items: center;
  margin-bottom: 28px;
}
.jackpot-item .jackpot-ava {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 12px;
}
.jackpot-item .jackpot-name__fullname {
  font-size: 18px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 5px;
}
.jackpot-item .jackpot-name__nickname {
  font-size: 12px;
  color: #91a7ba;
  font-weight: 500;
}
.jackpot-item .jackpot-info {
  margin-left: auto;
  text-align: right;
}
.jackpot-item .jackpot-time {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 500;
  color: #ff2837;
}
.jackpot-item .jackpot-balance {
  font-size: 24px;
  font-weight: 500;
  color: #fff;
}
.jackpot-item .jackpot-btn {
  height: 60px;
  background-color: #0c2640;
  border-radius: 6px;
  padding-left: 30px;
  padding-right: 18px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  color: #fff;
}
.jackpot-item .jackpot-btn svg {
  width: 14px;
  height: 14px;
  fill: #fd2739;
}
.jackpot-item.gradient .jackpot-time,
.jackpot-item.gradient .jackpot-name__nickname {
  color: #f57abc;
}
.jackpot-item.gradient .jackpot-btn {
  background-color: rgba(12, 38, 64, 0.25);
}
.jackpot-item.gradient .jackpot-btn svg {
  fill: #ffacde;
}
.jackpot-item:last-child {
  margin-bottom: 0;
}

.gradient {
  background-image: -moz-linear-gradient(0deg, #ff2837 0%, #c80469 100%);
  background-image: -webkit-linear-gradient(0deg, #ff2837 0%, #c80469 100%);
  background-image: -ms-linear-gradient(0deg, #ff2837 0%, #c80469 100%);
  background-image: linear-gradient(0deg, #ff2837 0%, #c80469 100%);
}

.daily-jackpot {
  font-family: "HelloDenver";
  background: url(/4a9a9d22d85ef4bf9b70716c075639d1.png) no-repeat;
  background-size: 100% 100%;
  max-width: 50%;
  text-align: center;
  flex-grow: 1;
  position: relative;
}
.daily-jackpot::before, .daily-jackpot::after {
  content: "";
  background: url(/33185f2efa828fe7a1969f020b0c1ff4.png) no-repeat;
  background-size: 100% 100%;
  width: 433px;
  height: 611px;
  position: absolute;
  top: -40px;
}
.daily-jackpot::before {
  left: -165px;
}
.daily-jackpot::after {
  right: -170px;
  background-image: url(/26f1cfb98554caeb87bc4fbc5d745bb9.png);
}
.daily-jackpot .daily-logo {
  background: url(/f681f0f3173f9ae99e169c6a1dfbbe59.png) no-repeat;
  width: 410px;
  height: 240px;
  position: absolute;
  left: 50%;
  top: -28px;
  transform: translateX(-50%);
}
.daily-jackpot .daily-timer {
  margin-top: 190px;
  font-size: 60px;
  text-shadow: 0px 2px 16px #fa0e99;
  color: #ffcee2;
}
.daily-jackpot .daily-balance {
  margin-top: 80px;
  color: #ffdaab;
  font-size: 60px;
  text-shadow: 0px 2px 16px #e0820a;
  position: relative;
  display: inline-flex;
}
.daily-jackpot .daily-balance::before, .daily-jackpot .daily-balance::after {
  content: "";
  background: url(/86f852912c91ceec625340df0a8386b6.png) no-repeat;
  background-size: contain;
  width: 52px;
  height: 43px;
  position: absolute;
  top: 6px;
}
.daily-jackpot .daily-balance::before {
  left: -75px;
}
.daily-jackpot .daily-balance::after {
  right: -75px;
  background-image: url(/2da9e902524618cc6adfbb72cb005f1a.png);
}

.privacy p {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 25px;
}
.privacy a {
  color: #fe2838;
}
.privacy ul {
  margin-bottom: 25px;
}
.privacy ul li {
  color: #fe2838;
}
.privacy ul li span {
  color: #91a7ba;
}
.privacy blockquote {
  border-top: 1px solid #062035;
  border-bottom: 1px solid #062035;
  border-left: none;
  padding-left: 0;
  margin-bottom: 50px;
}
.privacy blockquote span {
  margin-top: 26px;
  margin-bottom: 26px;
  padding-left: 25px;
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  border-left: 5px solid #fe2838;
}
.privacy .privacy-title {
  margin-bottom: 35px;
  color: #fff;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
}
.privacy .privacy-title span {
  color: #fe2838;
}

.game-frame {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.game-frame .info-block {
  height: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  max-width: 25%;
}

.game-container {
  background-size: 100% 100%;
  flex-grow: 1;
  max-width: 100%;
  height: 750px;
}

.game {
  height: 100%;
  width: 100%;
  border: unset;
}

.game-img {
  width: 100%;
  height: 219px;
  margin-bottom: 38px;
}

.game-description p {
  color: #91a7ba;
  font-size: 14px;
  line-height: 1.571;
}

.game-info {
  height: 129px;
  background-color: #001935;
  border-radius: 6px;
  margin-top: 75px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 35px 25px;
}
.game-info__item {
  width: 40%;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #fff;
}
.game-info__item svg {
  fill: #4fef00;
  width: 18px;
  height: 18px;
}
.game-info__item .status {
  color: #496c92;
  font-size: 14px;
  font-weight: 700;
  margin-left: auto;
}
.game-info__item:nth-of-type(3), .game-info__item:nth-of-type(4) {
  margin-bottom: 0;
}

.slider-btns {
  margin-left: auto;
  margin-right: 8px;
}

.btn-slider {
  margin-left: 5px;
  padding: 0;
  width: 52px;
  height: 52px;
  border: none;
  transform: none;
  background-color: #001935;
}
.btn-slider svg {
  width: 14px;
  height: 14px;
  fill: #496c92;
}
.btn-slider:hover {
  background-image: -moz-linear-gradient(0deg, #c80469 0%, #ff2837 100%);
  background-image: -webkit-linear-gradient(0deg, #c80469 0%, #ff2837 100%);
  background-image: -ms-linear-gradient(0deg, #c80469 0%, #ff2837 100%);
  background-image: linear-gradient(0deg, #c80469 0%, #ff2837 100%);
}
.btn-slider:hover svg {
  fill: #fff;
}
.btn-slider__prev svg {
  transform: rotate(-180deg);
}

.modal__profile {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 32px;
}
.modal__profile .profile__name::before,
.modal__profile .profile__name::after {
  display: none;
}

.modal__profile__info {
  flex-grow: 1;
  max-width: 50%;
  width: 100%;
  display: flex;
  align-items: center;
}
.modal__profile__info .username {
  background-color: transparent;
  border: none;
  outline: none;
  margin-right: 30px;
  padding: 4px 50px 4px 0;
  min-width: 170px;
  display: flex;
  position: relative;
  z-index: 900;
}
.modal__profile__info .username:focus {
  border: 1px solid #496c92;
  border-radius: 6px;
}
.modal__profile__info .username:focus svg {
  display: block;
}
.modal__profile__info .profile__bio {
  display: flex;
}
.modal__profile__info .username-edit {
  position: relative;
  left: 0;
  top: 0;
}
.modal__profile__info .username-edit svg {
  position: absolute;
  top: 6px;
  right: 35px;
  z-index: 999;
  opacity: 0;
  max-width: 30px;
  max-height: 33px;
  margin-left: 40px;
  fill: #ff2837;
}
.modal__profile__info .username-edit .profile-edit__save--show {
  opacity: 1;
}

.forgot-password-btn {
  width: 190px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to right, #ff2837 0%, #c80469 100%);
  border-radius: 6px;
  font-size: 16px;
  color: #ffffff;
  margin-top: 36px;
  cursor: pointer;
}

.username {
  font-size: 30px;
  color: #fff;
  font-weight: 700;
  padding-right: 50px;
}

.verified {
  display: inline-flex;
  align-items: center;
  font-weight: 700;
  color: #4ce504;
}
.verified svg {
  max-width: 18px;
  max-height: 18px;
  margin-right: 5px;
  fill: #4ce504;
}

.profile__ava {
  width: 120px;
  height: 120px;
  border-radius: 6px;
  border: 2px solid #ff2837;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
}
.profile__ava:hover .profile-edit {
  transform: translateY(0);
}

.profile-edit {
  position: absolute;
  left: 0;
  bottom: 0;
  font-size: 12px;
  font-weight: 300;
  color: #fff;
  background-color: #000;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  cursor: pointer;
  transform: translateY(100%);
  transition: 0.3s;
}
.profile-edit svg {
  max-width: 14px;
  max-height: 13px;
  margin-right: 5px;
  fill: #fff;
}

.modal__profile__status {
  background-color: #06203b;
  border-radius: 6px;
  padding: 20px;
  flex-grow: 1;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.modal__profile__status svg {
  width: 15px;
  height: 15px;
  margin-right: 6px;
}
.modal__profile__status svg.star-silver {
  fill: #8c8b8f;
}
.modal__profile__status svg.star-gold {
  fill: #d4af37;
}
.modal__profile__status .status-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal__profile__status .status-info.small {
  font-size: 12px;
}
.modal__profile__status .status-info.small svg {
  width: 11px;
  height: 11px;
}
.modal__profile__status .status-name {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
}
.modal__profile__status .status-profit {
  font-size: 16px;
  font-weight: 500;
  color: #ff2837;
}
.modal__profile__status .status-bar {
  width: 100%;
  height: 16px;
  background-color: #112d49;
  border-radius: 8px;
  overflow: hidden;
}
.modal__profile__status .status-bar__line {
  background: linear-gradient(-45deg, #fc263a, #ca0568);
  height: 16px;
  border-radius: 8px;
  display: block;
}
.modal__profile__status .status-next {
  color: #91a7ba;
}
.modal__profile__status .status-rang {
  color: #fff;
  font-weight: 700;
}

.modal__history--table {
  display: flex;
  flex-direction: column;
}
.modal__history--table .modal__history--row {
  height: 68px;
  border-radius: 6px;
  padding: 19px;
}
.modal__history--table .modal__history--row:nth-child(odd) {
  background-color: #0c2640;
}
.modal__history--table .modal__history--row:nth-child(even) {
  background-color: #001935;
}
.modal__history--table .modal__history--row:first-child {
  height: auto;
  background-color: transparent;
  padding-bottom: 0;
}
.modal__history--table .modal__history--row:first-child .modal__history--item .title {
  color: #324e72;
}
.modal__history--table .modal__history--row:first-child .modal__history--item .modal-rate {
  border-radius: 0;
  background-color: transparent;
  width: auto;
}
.modal__history--table .modal__history--item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal__history--table .modal__history--item > * {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #fff;
  font-weight: 700;
  width: calc(100% / 6);
  justify-content: center;
}
.modal__history--table .modal__history--item svg {
  width: 23px;
  height: 24px;
  fill: #476183;
  margin-right: 10px;
}
.modal__history--table .modal__history--item .modal-rate {
  border-radius: 50%;
  background-color: #1c3b5d;
  font-weight: 900;
  color: #fff;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
}
.modal__history--table .modal__history--item .modal-balance-after,
.modal__history--table .modal__history--item .modal-status {
  color: #4ce504;
  font-size: 14px;
  font-weight: 700;
}
.modal__history--table .modal__history--item .modal-status.succes {
  color: #4ce504;
}
.modal__history--table .modal__history--item .modal-status.succes svg {
  fill: #4ce504;
}
.modal__history--table .modal__history--item .modal-status.error {
  color: #ff2837;
}
.modal__history--table .modal__history--item .modal-status.error svg {
  fill: #ff2837;
}
.modal__history--table .modal__history--item .modal-status.neutral {
  color: #476183;
}
.modal__history--table .modal__history--item .modal-status.neutral svg {
  fill: #476183;
}

.tab.deposits {
  overflow-x: hidden;
  overflow-y: hidden;
}
.tab.deposits .left {
  width: 590px;
  margin-right: 30px;
}
.tab.deposits .total {
  display: flex;
  align-items: center;
}
.tab.deposits .total .item {
  display: flex;
  flex-direction: column;
}
.tab.deposits .total .item label {
  color: #324e72;
  font-weight: 400;
}
.tab.deposits .total .item input {
  margin-top: 24px;
  background-color: #062445;
  outline: none;
  border: none;
  height: 65px;
  padding-left: 24px;
  border-radius: 6px;
  color: white;
}
.tab.deposits .total .item input.sum {
  width: 385px;
  margin-right: 4px;
}
.tab.deposits .total .item input.total {
  width: 200px;
}
.tab.deposits .pay {
  margin-top: 40px;
}
.tab.deposits .pay-text {
  color: #324e72;
  font-size: 14px;
  font-weight: 400;
}
.tab.deposits .pay-block {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.tab.deposits .pay-item {
  width: 190px;
  height: 135px;
  border-radius: 6px;
  background-color: #062445;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.tab.deposits .pay-item input {
  display: none;
}
.tab.deposits .pay-item.checked {
  background-image: linear-gradient(to right, #ff2837 0%, #c80469 100%);
}
.tab.deposits .deposit-btn {
  width: 190px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to right, #ff2837 0%, #c80469 100%);
  border-radius: 6px;
  font-size: 16px;
  color: #ffffff;
  margin-top: 36px;
  cursor: pointer;
}
.tab.deposits .right {
  display: flex;
  flex-direction: column;
}
.tab.deposits .right .table-header {
  display: flex;
  width: 100%;
  margin-bottom: 22px;
}
.tab.deposits .right .table-header__item {
  color: #324e72;
  font-size: 14px;
  font-weight: 400;
  width: calc(100% / 4);
  display: flex;
  justify-content: center;
}
.tab.deposits .right .table-body {
  display: flex;
  justify-content: space-between;
  background-color: #062445;
  border-radius: 6px;
  height: 65px;
  margin-bottom: 4px;
}
.tab.deposits .right .table-body__item {
  width: calc(100% / 4);
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tab.deposits .right .table-body__item span {
  width: 30px;
  height: 30px;
  background-color: #1c3b5d;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  font-weight: 400;
}
.tab.deposits .right .table-body__item svg {
  width: 18px;
  height: 18px;
  margin-right: 15px;
}

.tab.withdraw {
  overflow-x: hidden;
}
.tab.withdraw .left {
  width: 590px;
  margin-right: 30px;
}
.tab.withdraw .total {
  display: flex;
  align-items: center;
}
.tab.withdraw .total .item {
  display: flex;
  flex-direction: column;
}
.tab.withdraw .total .item label {
  color: #324e72;
  font-weight: 400;
}
.tab.withdraw .total .item input {
  margin-top: 24px;
  background-color: #062445;
  outline: none;
  border: none;
  height: 65px;
  padding-left: 24px;
  border-radius: 6px;
  color: white;
}
.tab.withdraw .total .item input.sum {
  width: 385px;
  margin-right: 4px;
}
.tab.withdraw .total .item input.total {
  width: 200px;
}
.tab.withdraw .card {
  margin-top: 2px;
  display: flex;
  align-items: center;
}
.tab.withdraw .card-number input {
  background-color: #062445;
  outline: none;
  border: none;
  height: 65px;
  padding-left: 24px;
  border-radius: 6px;
  color: white;
  width: 385px;
  margin-right: 4px;
}
.tab.withdraw .card-data {
  display: flex;
  align-items: center;
}
.tab.withdraw .card-data input.date, .tab.withdraw .card-data input.cvv {
  background-color: #062445;
  outline: none;
  border: none;
  height: 65px;
  padding-left: 24px;
  border-radius: 6px;
  color: white;
  width: 98px;
  margin-right: 4px;
}
.tab.withdraw .card-data input.date {
  margin-right: 4px;
}
.tab.withdraw .pay {
  margin-top: 40px;
}
.tab.withdraw .pay-text {
  color: #324e72;
  font-size: 14px;
  font-weight: 400;
}
.tab.withdraw .pay-block {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.tab.withdraw .pay-item {
  width: 190px;
  height: 135px;
  border-radius: 6px;
  background-color: #062445;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.tab.withdraw .pay-item input {
  display: none;
}
.tab.withdraw .pay-item.checked {
  background-image: linear-gradient(to right, #ff2837 0%, #c80469 100%);
}
.tab.withdraw .deposit-btn {
  width: 190px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to right, #ff2837 0%, #c80469 100%);
  border-radius: 6px;
  font-size: 16px;
  color: #ffffff;
  margin-top: 36px;
  cursor: pointer;
}
.tab.withdraw .right .table-header {
  display: flex;
  width: 100%;
  margin-bottom: 22px;
}
.tab.withdraw .right .table-header__item {
  color: #324e72;
  font-size: 14px;
  font-weight: 400;
  width: calc(100% / 4);
  display: flex;
  justify-content: center;
}
.tab.withdraw .right .table-body {
  display: flex;
  justify-content: space-between;
  background-color: #062445;
  border-radius: 6px;
  height: 65px;
  margin-bottom: 4px;
}
.tab.withdraw .right .table-body__item {
  width: calc(100% / 4);
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tab.withdraw .right .table-body__item span {
  width: 30px;
  height: 30px;
  background-color: #1c3b5d;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  font-weight: 400;
}
.tab.withdraw .right .table-body__item svg {
  width: 18px;
  height: 18px;
  margin-right: 15px;
}

.tab.security .form {
  display: flex;
  align-items: center;
}
.tab.security .form .item {
  position: relative;
}
.tab.security .form .item input {
  background-color: #062445;
  outline: none;
  border: none;
  height: 65px;
  padding-left: 60px;
  border-radius: 6px;
  color: white;
  width: 365px;
  margin-right: 16px;
  font-weight: 400;
}
.tab.security .form .item img {
  position: absolute;
  top: 50%;
  left: 27px;
  transform: translateY(-50%);
}
.tab.security .security-btn {
  width: 190px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to right, #ff2837 0%, #c80469 100%);
  border-radius: 6px;
  font-size: 16px;
  color: #ffffff;
  margin-top: 36px;
  cursor: pointer;
}

.error-background {
  border-style: solid !important;
  border-width: 1px !important;
  border-color: #c22436 !important;
  background-color: rgba(194, 36, 54, 0.18) !important;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.infotext {
  margin-top: 5px;
}
.infotext.success--message {
  color: #4ce504;
}
.infotext.error--message {
  color: #c22436;
}

.verify-container {
  display: flex;
  justify-content: center;
  min-height: 200px;
  align-items: center;
}

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #1c3b5d;
}

::-webkit-scrollbar-thumb:hover {
  background: #062445;
}

@media (min-width: 320px) and (max-width: 800px) {
  body {
    padding-left: 0;
  }

  .mobile {
    display: block;
  }

  .container {
    padding-left: 18px;
    padding-right: 18px;
  }

  .sidebar {
    display: none;
  }

  .section-top {
    flex-wrap: wrap;
  }
  .section-top .btn {
    margin-left: auto;
    margin-right: auto;
  }

  .section-text {
    align-items: flex-start;
    max-width: 90%;
  }
  .section-text .section-icon {
    width: 22px;
    height: 22px;
    flex-shrink: 0;
  }
  .section-text .section-title {
    font-size: 24px;
    line-height: 1.2;
  }

  .information-text {
    text-align: center;
  }

  .daily-col,
.daily-jackpot {
    width: 100%;
    max-width: 100%;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .daily-col .jackpot-item,
.daily-jackpot .jackpot-item {
    margin-bottom: 18px;
    padding: 18px 25px;
  }

  .daily-jackpot {
    margin-top: 18px;
    margin-bottom: 18px;
    height: 410px;
    background-image: url(/c0e935bd3fcc59aa8e68dd135dc5e85c.png);
  }
  .daily-jackpot::before, .daily-jackpot::after {
    width: 61px;
    height: 271px;
    top: 0;
    background-size: contain;
  }
  .daily-jackpot::before {
    left: -20px;
    bottom: -20px;
    top: auto;
    background-image: url(/1a6e35de75c4e0d36cc481a3000cd709.png);
  }
  .daily-jackpot::after {
    right: -20px;
    width: 87px;
    height: 271px;
    bottom: -20px;
    top: auto;
    background-image: url(/934584d3530b6ae1afbbca3523b6e97f.png);
  }
  .daily-jackpot .daily-logo {
    width: 235px;
    max-height: 114px;
    background-size: contain;
    background-position: 50% 0;
    top: 0;
  }
  .daily-jackpot .daily-timer {
    font-size: 48px;
    margin-top: 210px;
  }
  .daily-jackpot .daily-balance {
    font-size: 48px;
  }

  .header__menu {
    margin-right: 22px;
    width: 20px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 22;
  }
  .header__menu span {
    width: 100%;
    height: 1px;
    display: block;
    background-color: #2c4669;
  }
  .header__menu span::before, .header__menu span::after {
    content: "";
    width: 100%;
    height: 1px;
    position: absolute;
    background-color: #2c4669;
  }
  .header__menu span::before {
    top: 0;
  }
  .header__menu span::after {
    bottom: 0;
  }
  .header__menu.active span {
    background-color: transparent;
  }
  .header__menu.active span::before, .header__menu.active span::after {
    top: 50%;
    height: 2px;
    border-radius: 2px;
  }
  .header__menu.active span::before {
    transform: rotate(45deg);
  }
  .header__menu.active span::after {
    transform: rotate(-45deg);
  }

  .header {
    padding-left: 18px;
    padding-right: 18px;
  }
  .header .header__logo {
    width: 67px;
    height: 64px;
    margin-left: 42px;
  }
  .header .header__languages,
.header .header__search,
.header .header__navigation {
    display: none;
  }
  .header .social__block {
    display: none;
  }
  .header .header__login {
    margin-left: 0;
  }
  .header .header__profile .profile__notification {
    margin-right: 20px;
  }
  .header .header__profile .profile__card .profile__name, .header .header__profile .profile__card .profile__arrow-down {
    display: none;
  }
  .header .header__profile .profile__card .profile__ava {
    margin-right: 0;
  }
  .header .header__profile .profile__logout {
    display: none;
  }
  .header .header__login--btn {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 16px;
    margin-right: 15px;
  }

  body {
    padding-bottom: 0;
  }

  .footer {
    left: 0;
    padding-right: 0;
    height: auto;
    position: relative;
  }
  .footer .payments-list {
    flex-wrap: wrap;
    justify-content: center;
  }
  .footer .payments-list__item {
    width: 33%;
    margin-bottom: 24px;
  }
  .footer .payments-list__item img {
    max-width: 70%;
    max-height: 30px;
    margin: 0 auto;
  }
  .footer .section--wrap {
    padding-top: 25px;
    margin-top: 25px;
    padding-left: 18px;
    padding-right: 18px;
    padding-bottom: 106px;
  }
  .footer .col {
    margin-right: 0;
    width: 50%;
  }
  .footer .col:nth-of-type(1) {
    width: 100%;
    order: 0;
    margin-bottom: 36px;
  }
  .footer .col:nth-of-type(2) {
    order: 2;
    margin-bottom: 36px;
  }
  .footer .col:nth-of-type(3) {
    order: 1;
  }
  .footer .col:nth-of-type(4) {
    order: 3;
    margin-top: 30px;
  }
  .footer .copyright, .footer .social__block {
    position: absolute;
    bottom: 0;
    width: 90%;
    text-align: center;
  }
  .footer .copyright::before {
    content: "";
    width: calc(100% + 36px);
    height: 1px;
    position: absolute;
    left: -18px;
    right: -18px;
    top: -20px;
    background-color: #062035;
  }

  .start-frame {
    margin-right: 0;
    height: auto;
    max-height: 737px;
    margin-bottom: 36px;
  }

  .info-block {
    min-width: 100%;
  }

  .top-winners {
    padding-left: 0;
    padding-right: 34px;
  }
  .top-winners::before {
    right: 0;
    left: auto;
    top: -150px;
    width: 31px;
    height: 458px;
    background: url(/6263ccbe548732c1f13575c7461c5c8c.png) no-repeat;
    border: none;
  }
  .top-winners .winner-item::before {
    display: none;
  }
  .top-winners .winner-item::after {
    left: auto;
    right: -45px;
  }

  .games-tabs__list {
    flex-wrap: wrap;
  }
  .games-tabs__list--item {
    width: 33.3%;
    margin-right: 0;
    margin-bottom: 32px;
    justify-content: center;
  }

  .games-item {
    max-width: 100%;
    margin-right: 0;
    height: auto;
    max-height: unset;
  }
  .games-item__info {
    height: auto;
  }
}
@media screen and (max-width: 1600px) {
  .join-block .leprecon {
    width: 370px;
    top: 0;
  }

  .games-item__description {
    max-height: 4rem;
  }
}
@media screen and (max-width: 1440px) {
  .jackpot-item {
    max-height: unset;
    height: auto;
  }
  .jackpot-item .jackpot-ava {
    min-width: 60px;
  }

  .jackpot-item__top {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .jackpot-name {
    margin-top: 16px;
  }

  .jackpot-item .jackpot-info {
    margin-left: 0;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .jackpot-item .jackpot-info .jackpot-time {
    text-align: center;
  }
}
@media screen and (max-width: 1350px) {
  .header__navigation--link {
    margin-right: 10px;
  }
  .header__navigation--link svg {
    min-width: 20px;
    min-height: 20px;
  }
  .header__navigation--link:last-of-type {
    margin-right: 10px;
  }

  .social__block {
    display: none;
  }
}
@media screen and (max-width: 1100px) {
  .jackpot-item {
    padding: 16px;
  }

  .games-item__description {
    max-height: 3rem;
  }
}
@media screen and (max-width: 1000px) {
  .wrapper:nth-child(1) {
    flex-direction: column;
  }

  .info-block {
    margin-top: 32px;
    max-width: 100%;
  }

  .games-tabs__list--item {
    margin-right: 16px;
  }

  .payments-list {
    flex-wrap: wrap;
  }
  .payments-list__item {
    width: 30%;
    margin-bottom: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 900px) {
  .header {
    padding: 0 16px;
  }

  .start-frame {
    margin-right: 0;
  }

  .section-top .btn {
    display: none;
  }

  .btn.second {
    margin-top: 32px;
    height: 50px;
    padding-left: 25px;
    padding-right: 25px;
    color: #fff;
    text-decoration: none;
    font-weight: 500;
  }
  .btn.second span {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .btn.second span svg {
    margin-left: 100px;
    width: 14px;
    height: 14px;
    fill: #ff2837;
  }

  .games-item__description {
    max-height: 2rem;
  }
}
@media screen and (max-width: 800px) {
  .daily-jackpot {
    order: 1;
    width: 100%;
    max-width: 100%;
  }

  .daily-col {
    display: flex;
    margin-top: 32px;
    margin-left: 0 !important;
    flex-direction: row;
    width: 100%;
    max-width: 100%;
    justify-content: space-between;
  }

  .jackpot-item {
    width: 48% !important;
    max-width: 48% !important;
    margin-bottom: 0;
  }

  .jackpot .wrapper {
    flex-direction: column-reverse;
  }
}
@media screen and (max-width: 500px) {
  .footer .col {
    width: 100%;
  }
  .footer .col:nth-of-type(2) {
    margin-bottom: 0;
  }
  .footer .col:nth-child(4) {
    margin-top: 0;
  }
}